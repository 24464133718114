import React from "react"
import Layout from "../components/layout/Layout"
import { IMetadata } from "../model/IMetadata"
import Section from "../components/section/Section"
import * as styles from "./404.module.scss"
import { StaticImage } from "gatsby-plugin-image"
import { SpeechBubble } from "../components/SpeechBubble"
import { AppContextProvider } from "../components/AppContext"
import { ScaleToWindowWidth } from "../components/ScaleToWindowWidth"

const PAGE_NOT_FOUND_METADATA: IMetadata = {
  metaTitle: 'Page Not Found',
  metaDescription: `Oops! Sorry, we really can't find that page.`,
  shareImage: null
}

const PageNotFoundPage: React.FC = () => {
  return (
    <AppContextProvider context={{
      currentLocale: "en",
      currentSlug: "404"
    }}>
      <Layout pageSeo={PAGE_NOT_FOUND_METADATA}>
        <Section className={styles.Section404}>
          <ScaleToWindowWidth>
            {(ref, scale) => (
              <div ref={ref} className={styles.content}
                 style={{
                   transform: `scale(${scale}, ${scale})`,
                   transformOrigin: 'top'
                 }}
              >
                <div>
                  <SpeechBubble
                    size={{
                      width: 430,
                      height: 180
                    }}
                    listenForUpdates={false}
                  >
                    <span className={styles.bubbleContent}>
                      <span className={styles.highlight}>oops!</span><br/>
                      <span className={styles.body}>
                        {"Sorry, we really"}<br/>{"can't find that page"}
                      </span>
                    </span>
                  </SpeechBubble>
                </div>
                <StaticImage
                  className={styles.image}
                  src='../images/404_image.png'
                  alt=''
                  width={465}
                  quality={100}
                />
              </div>
            )}
          </ScaleToWindowWidth>
        </Section>
      </Layout>
    </AppContextProvider>
  )
}

export default PageNotFoundPage